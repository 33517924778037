import * as React from 'react';
import {Button, Container, Grid, Typography, useMediaQuery} from "@mui/material";
import mimonatega from "../techLogos/mimonatega.png";

export default function Mimonatega() {
    // @ts-ignore
    const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={1} md={3} style={matches ? {display: "none"} : {}}>
                    <img src={mimonatega} alt={"mimonatega logo"} style={{width: "100%", height: "auto"}}/>
                </Grid>
                <Grid item xs={12} md={9}>
                    {matches ?
                        <Grid container spacing={3} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                        }}>
                            <Grid item xs={3}>
                                <img src={mimonatega} alt={"mimonatega logo"} style={{width: "100%", height: "auto"}}/>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant={"h4"}>Mimonatega</Typography>
                            </Grid>
                        </Grid> : <Typography variant={"h4"}>Mimonatega</Typography>
                    }
                    <br/>
                    <br/>
                    <Typography>The "Mimonatega" project is an open source price aggregator from the
                        Mimovrste online store. It was created to record changes in prices and discounts.
                        Data is automatically obtained by periodic crawling on the mimovrste.com
                        website.</Typography>
                    <br/>
                    <Button variant={"outlined"}
                            onClick={() => window.open("https://beta.mimonatega.td-fl.org", '_blank')}>Visit the
                        website</Button>
                    <Button style={{marginLeft: "10px"}} variant={"outlined"}
                            onClick={() => window.open("https://github.com/ExidCuter/mimonatega-ext", '_blank')}>Source</Button>
                </Grid>
            </Grid>
        </Container>
    );
}
