import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// @ts-ignore
export default function TopBar({titleClick, aboutClick, projectClick}) {
    return (
        <Box sx={{ flexGrow: 1, position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000}}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor:"pointer" }} onClick={titleClick}>
                        TD-FL
                    </Typography>
                    <Button color="inherit" onClick={aboutClick}>About</Button>
                    <Button color="inherit" onClick={projectClick}>Projects</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
