import * as React from 'react';
import {
    Container,
    Grid,
} from "@mui/material";
import Typed from "react-typed";

export default function Footer() {
    return (
        <div>
            <Container>
                <Grid container spacing={5}>
                    <Grid item xs={12} onClick={() => window.open("https://github.com/TD-FL", '_blank')}>
                        <Typed style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                        }}
                               strings={[
                                   'https://github.com/TD-FL',
                                   'https://github.com/ExidCuter',
                                   'https://github.com/KoprivcLuka',
                               ]}
                               backDelay={20}
                               backSpeed={60}
                               typeSpeed={60}
                               loop
                        />
                        <br/>
                        <Typed style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                        }}
                               strings={[
                                   'Copyright TD-FL 2022',
                               ]}
                               backDelay={20}
                               backSpeed={60}
                               typeSpeed={60}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
