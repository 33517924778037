import * as React from 'react';
import {Container, Grid, Typography} from "@mui/material";

export default function About() {
    return (
        <div style={{marginTop: 100}}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Typography variant={"h4"}>We are ex students from FERI that are using their free time for fun
                            "useful" projects.</Typography>
                        <br/>
                        <Typography variant={"h4"}>We always try to learn something new or learn to despise some new
                            tehnology</Typography>
                    </Grid>
                </Grid>

            </Container>
        </div>
    );
}
