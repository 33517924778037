import * as React from 'react';
import {Container, Grid, Typography} from "@mui/material";
import docker from "../techLogos/docker.svg";
import angular from "../techLogos/angular.png";
import spring from "../techLogos/spring.png";
import react from "../techLogos/react.png";
import gitlab from "../techLogos/gitlab.png";
import android from "../techLogos/android.png";


export default function Technologies() {
    return (
        <div style={{marginTop: 100}}>
            <Container>
                <Grid container spacing={2} style={{marginTop: '10px'}}>
                    <Grid item xs={12} md={12}>
                        <Typography variant={"h5"}>Technologies we have used and have a love/hate relationship with:</Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <img src={docker} alt="docker logo" style={{width:"100%", height: "auto"}} />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <img src={angular} alt="angular logo" style={{width:"100%", height: "auto"}} />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <img src={spring} alt="spring logo" style={{width:"100%", height: "auto"}} />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <img src={react} alt="react logo" style={{width:"100%", height: "auto"}} />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <img src={gitlab} alt="gitlab logo" style={{width:"100%", height: "auto"}} />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <img src={android} alt="android logo" style={{width:"100%", height: "auto"}} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
