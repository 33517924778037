import * as React from 'react';
import {Button, Container, Grid, Typography, useMediaQuery} from "@mui/material";
import umu from "../techLogos/umu.png";

export default function Umu() {
    // @ts-ignore
    const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={3} md={3} style={matches ? {display: "none"} : {}}>
                    <img src={umu} alt={"mimonatega logo"} style={{width: "100%", height: "auto"}}/>
                </Grid>
                <Grid item xs={12} md={9}>
                    {matches ?
                        <Grid container spacing={3} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                        }}>
                            <Grid item xs={3}>
                                <img src={umu} alt={"mimonatega logo"} style={{width: "100%", height: "auto"}}/>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant={"h4"}>UMU - Urnik Mariborske Univerze</Typography>
                            </Grid>
                        </Grid> : <Typography variant={"h4"}>UMU - Urnik Mariborske Univerze</Typography>
                    }
                    <br/>
                    <Typography>The UMU project is an open source aggregator of schedules of various
                        faculties, which, using a powerful and fast back-office system implemented in the GO
                        language, enables automatic and periodic analysis of schedule data from various data
                        sources. We currently have 15 faculties from the University of Maribor in the
                        system.</Typography>
                    <br/>
                    <Button variant={"outlined"} onClick={() => window.open("https://urnik.td-fl.org", '_blank')}>Visit
                        the website</Button>
                    <Button style={{marginLeft: "10px"}} variant={"outlined"}
                            onClick={() => window.open("https://github.com/TD-FL", '_blank')}>Source</Button>
                </Grid>
            </Grid>
        </Container>
    );
}
