import * as React from 'react';
import {Button, Container, Grid, Typography, useMediaQuery} from "@mui/material";
import dodobot from "../techLogos/dodobot.jpg";

export default function JDodoBot() {
    // @ts-ignore
    const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                    {matches ?
                        <Grid container spacing={3} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                        }}>
                            <Grid item xs={3}>
                                <img src={dodobot} alt={"mimonatega logo"} style={{width: "100%", height: "auto"}}/>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant={"h4"}>JDodoBot - A Discord Bot</Typography>
                            </Grid>
                        </Grid> : <Typography variant={"h4"}>JDodoBot - A Discord Bot</Typography>
                    }
                    <br/>
                    <Typography>A fun Discord bot built with Java and JDA. It has a lot of functionalities
                        ranging from server management to fun commands.</Typography>
                    <br/>
                    <Button variant={"outlined"}
                            onClick={() => window.open("https://discord.com/oauth2/authorize?client_id=277458741052571648&scope=bot&permissions=2146958591", '_blank')}>Add
                        it to your Server</Button>
                    <Button style={{marginLeft: "10px"}} variant={"outlined"}
                            onClick={() => window.open("https://github.com/ExidCuter/JDodoBot", '_blank')}>Source</Button>
                </Grid>
                <Grid item xs={1} md={3} style={matches ? {display: "none"} : {}}>
                    <img src={dodobot} alt={"mimonatega logo"} style={{width: "100%", height: "auto"}}/>
                </Grid>
            </Grid>
        </Container>
    );
}
