import * as React from 'react';
import Typed from "react-typed";

export default function TypeText() {
    return (
        <div style={{marginTop: 100}}>
            <Typed
                strings={[
                    'import {bug}',
                    'import {fix} from @td-fl;'
                ]}
                backDelay={20}
                backSpeed={60}
                typeSpeed={60}
                loop
            />
        </div>
    );
}
