import React, {useRef} from 'react';
import {Parallax, ParallaxLayer} from "@react-spring/parallax";
import TopBar from "./topBar/topBar";
import Intro from "./pages/intro";
import About from "./pages/about";
import Technologies from "./pages/technologies";
import TypeText from "./pages/typeText";
import {useMediaQuery} from "@mui/material";
import Footer from "./pages/footer";
import Mimonatega from "./pages/mimonatega";
import JDodoBot from "./pages/jdodobot";
import Umu from "./pages/umu";

function App() {
    // @ts-ignore
    const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const paralaxRef = useRef(null);

    return (
        <div>
            {/* @ts-ignore */}
            <TopBar titleClick={() => paralaxRef.current.scrollTo(0)} aboutClick={() => paralaxRef.current.scrollTo(0.9)} projectClick={() => paralaxRef.current.scrollTo(1.9)}/>
            <Parallax pages={3.5} style={{top: '0', left: '0'}} ref={paralaxRef}>
                <ParallaxLayer
                    offset={0}
                    speed={0.5}
                    style={!matches ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                    } : {}}>
                    <Intro/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0.2}
                    speed={0.1}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                    }}>
                    <TypeText/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1}
                    speed={0.1}
                    factor={0.2}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                    }}>
                    <About/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={!matches ? 1.2 : 1.5}
                    factor={0.2}
                    speed={0.2}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                    }}>
                    <Technologies/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2}
                    factor={0.3}
                    speed={0.1}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                    }}>
                    <Mimonatega/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.5}
                    factor={0.2}
                    speed={0.2}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                    }}>
                    <JDodoBot/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.99}
                    factor={0.2}
                    speed={0.3}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                    }}>
                    <Umu/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.25}
                    factor={0.2}
                    speed={0.1}
                    style={{
                        backgroundColor: "#1975d0",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                    }}>
                    <Footer/>
                </ParallaxLayer>
            </Parallax>
        </div>
    );
}

export default App;
