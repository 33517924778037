import * as React from "react";
import {a, useTrail} from "react-spring";


// @ts-ignore
export const TrailEffect: React.FC<{ open: boolean }> = ({ open, children }) => {
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 2000, friction: 200, duration: 200 },
        opacity: open ? 1 : 0,
        x: open ? 0 : 20,
        height: open ? 80 : 0,
        from: { opacity: 0, x: 20, height: 0 },
    })
    return (
        <div>
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} style={style}>
                    <a.div >{items[index]}</a.div>
                </a.div>
            ))}
        </div>
    )
}
