import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {ThemeProvider} from "@mui/material";
import {tdflTheme} from "./theme/theme";

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={tdflTheme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

