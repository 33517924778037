import * as React from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {useSpring, animated} from 'react-spring'
import {TrailEffect} from "../effects/trailEffect";
import logo from '../logo.svg';


export default function Intro() {
    const appearEffect = useSpring({to: {opacity: 1}, from: {opacity: 0}, delay: 100, config: {duration: 1000}})

    // @ts-ignore
    const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <div style={matches ? {marginTop: 100} : {}}>
            <Grid container spacing={5}>
                <Grid item xs={12} md={4}>
                    <animated.div style={appearEffect}>
                        <img src={logo} className="App-logo" alt="logo" style={{width: "100%", height: "auto"}}/>
                    </animated.div>
                </Grid>
                <Grid item xs={12} md={8}>
                    <div style={{marginLeft: matches ? 10 : 0}}>
                        {/* @ts-ignore */}
                        <TrailEffect open={true}>
                            <Typography sx={{fontWeight: "bold"}} variant={matches ? "h5" : "h2"}>The BEST
                                From</Typography>
                            <Typography sx={{fontWeight: "bold"}} variant={matches ? "h5" : "h2"}>The CHAMPION Senior
                                Students</Typography>
                            <Typography sx={{fontWeight: "bold"}} variant={matches ? "h5" : "h2"}>For CHAMPIONS in their
                                fields</Typography>
                        </TrailEffect>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
